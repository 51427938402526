import React from "react";
import "./BillingForm.scss";
import { Form, Typography, Input } from "antd";
import Utils from "../../../../utils";

const { TextArea } = Input;
const { numberValidator, emailValidator } = Utils;

export default function BillingForm({
  formRef,
  addCartDetails,
  loading,
  error,
  dispatch,
  resetPatientError,
}) {
  return (
    <Form
      className="cartForm-cont"
      ref={formRef}
      layout="vertical"
      onFinish={(values) => {
        addCartDetails({
          ...values,
          phone_number: "+1" + values?.phone_number,
        });
      }}
      onFinishFailed={(error) => {
        console.log("onfinishFailed", error);
      }}>
      <div className="cartForm-cont__formItemWrapper">
        <Form.Item
          className="cartForm-cont__formItemWrapper__formItem"
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please enter your first name",
            },
          ]}
          label={
            <Typography.Text className="cartForm-cont__formItemWrapper__formItem__text">
              First Name
            </Typography.Text>
          }
          colon={false}>
          <Input
            placeholder="First name"
            size="large"
            disabled={loading}
            onChange={() => {
              if (error && error["first_name"]) {
                dispatch(resetPatientError("first_name"));
              }
            }}
          />
        </Form.Item>
        <Form.Item
          className="cartForm-cont__formItemWrapper__formItem"
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please enter your last name",
            },
          ]}
          label={
            <Typography.Text className="cartForm-cont__formItemWrapper__formItem__text">
              Last Name
            </Typography.Text>
          }
          colon={false}>
          <Input
            disabled={loading}
            placeholder="Last name"
            size="large"
            onChange={() => {
              if (error && error["last_name"]) {
                dispatch(resetPatientError("last_name"));
              }
            }}
          />
        </Form.Item>
      </div>
      <div className="cartForm-cont__formItemWrapper">
        <Form.Item
          className="cartForm-cont__formItemWrapper__formItem"
          name="phone_number"
          rules={[
            {
              required: true,
              message: "",
            },
            {
              validator: numberValidator,
            },
          ]}
          label={
            <Typography.Text className="cartForm-cont__formItemWrapper__formItem__text">
              Phone
            </Typography.Text>
          }
          colon={false}>
          <Input
            disabled={loading}
            placeholder="Phone"
            prefix="+1"
            size="large"
            onChange={() => {
              if (error && error["phone_number"]) {
                dispatch(resetPatientError("phone_number"));
              }
            }}
          />
        </Form.Item>
        <Form.Item
          className="cartForm-cont__formItemWrapper__formItem"
          name="email"
          rules={[
            {
              required: true,
              message: "",
            },
            {
              validator: emailValidator,
            },
          ]}
          label={
            <Typography.Text className="cartForm-cont__formItemWrapper__formItem__text">
              Email
            </Typography.Text>
          }
          colon={false}>
          <Input
            disabled={loading}
            type="text"
            placeholder="Email"
            size="large"
            onChange={() => {
              if (error && error["email"]) {
                dispatch(resetPatientError("email"));
              }
            }}
          />
        </Form.Item>
      </div>
      <div className="cartForm-cont__formItemWrapper">
        <Form.Item
          className="cartForm-cont__formItemWrapper__formItem"
          name="address"
          rules={[
            {
              required: true,
              message: "Please enter your address",
            },
          ]}
          label={
            <Typography.Text className="cartForm-cont__formItemWrapper__formItem__text">
              Address Line 1
            </Typography.Text>
          }
          colon={false}>
          <Input
            placeholder="Address Line 1"
            disabled={loading}
            size="large"
            onChange={() => {
              if (error && error["address"]) {
                dispatch(resetPatientError("address"));
              }
            }}
          />
        </Form.Item>
        <Form.Item
          className="cartForm-cont__formItemWrapper__formItem"
          style={{
            marginTop: "5px",
          }}
          name="address2"
          // rules={[
          //   {
          //     required: true,
          //     message: "Please enter your address line 2",
          //   },
          // ]}
          label={
            <Typography.Text className="cartForm-cont__formItemWrapper__formItem__text">
              Address Line 2
            </Typography.Text>
          }
          colon={false}>
          <Input
            placeholder="Address Line 2"
            disabled={loading}
            size="large"
            onChange={() => {
              if (error && error["address2"]) {
                dispatch(resetPatientError("address2"));
              }
            }}
          />
        </Form.Item>
      </div>
      <div className="cartForm-cont__formItemWrapper">
        <Form.Item
          className="cartForm-cont__formItemWrapper__formItem"
          name="city"
          rules={[
            {
              required: true,
              message: "Please enter your city",
            },
          ]}
          label={
            <Typography.Text className="cartForm-cont__formItemWrapper__formItem__text">
              City
            </Typography.Text>
          }
          colon={false}>
          <Input
            disabled={loading}
            placeholder="City"
            size="large"
            onChange={() => {
              if (error && error["city"]) {
                dispatch(resetPatientError("city"));
              }
            }}
          />
        </Form.Item>
        <div className="cartForm-cont__formItemWrapper__cont">
          <Form.Item
            className="cartForm-cont__formItemWrapper__formItem"
            name="state"
            rules={[
              {
                required: true,
                message: "Please enter your country and region",
              },
            ]}
            label={
              <Typography.Text className="cartForm-cont__formItemWrapper__formItem__text">
                State
              </Typography.Text>
            }
            colon={false}>
            <Input
              disabled={loading}
              type="text"
              placeholder="State"
              size="large"
              onChange={() => {
                if (error && error["state"]) {
                  dispatch(resetPatientError("state"));
                }
              }}
            />
          </Form.Item>
          <Form.Item
            className="cartForm-cont__formItemWrapper__formItem"
            name="zipcode"
            rules={[
              {
                required: true,
                message: "Please enter your zip code",
              },
              // {
              //   validator: numberValidator,
              // },
            ]}
            label={
              <Typography.Text className="cartForm-cont__formItemWrapper__formItem__text">
                Zip Code
              </Typography.Text>
            }
            colon={false}>
            <Input
              disabled={loading}
              placeholder="Zip code"
              size="large"
              onChange={() => {
                if (error && error["zipcode"]) {
                  dispatch(resetPatientError("zipcode"));
                }
              }}
            />
          </Form.Item>
        </div>
      </div>
      <div className="cartForm-cont__formItemWrapper">
        <Form.Item
          className="cartForm-cont__formItemWrapper__formItemLong"
          name="details"
          label={
            <Typography.Text className="cartForm-cont__formItemWrapper__formItem__text">
              Details
            </Typography.Text>
          }
          colon={false}>
          <TextArea
            disabled={loading}
            placeholder="Details"
            autoSize={{ minRows: 3, maxRows: 6 }}
            onChange={() => {
              if (error && error["details"]) {
                dispatch(resetPatientError("details"));
              }
            }}
          />
        </Form.Item>
      </div>
    </Form>
  );
}
