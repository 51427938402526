import React from "react";
import "./AppFooter.scss";
import { theme, Layout, Typography, Button } from "antd";
import {
  FacebookOutlined,
  InstagramOutlined,
  LinkedinOutlined,
  XOutlined,
  YoutubeOutlined,
} from "@ant-design/icons";
// import { FooterNavigation } from "./components";
import { useSelector } from "react-redux";

const { Footer } = Layout;

export default function AppFooter() {
  const { useToken } = theme;
  const { token } = useToken();
  const { info } = useSelector((state) => state?.auth);
  const { shop, loading: shopLoading } = useSelector(
    (state) => state?.shopDetails
  );

  return (
    <Footer
      className="appFooter-cont"
      style={{
        borderTop: `1px solid ${token?.Layout?.footerBorderTopColor}`,
      }}>
      <div className="appFooter-cont__socialMedia">
        {shopLoading ? (
          ""
        ) : shop?.name && shop?.is_name_visible_on_shop ? (
          <div className="appFooter-cont__socialMedia__titleWrapper">
            <Typography.Text
              className="appFooter-cont__socialMedia__titleWrapper__title"
              style={{
                color: token?.Typography?.colorPrimaryText,
              }}>
              {shop?.name}
            </Typography.Text>
          </div>
        ) : (
          ""
        )}

        <div className="appFooter-cont__socialMedia__iconsWrapper">
          <Button
            type="link"
            size="small"
            href="https://www.linkedin.com/"
            target="_blank"
            icon={
              <LinkedinOutlined
                style={{
                  fontSize: "18px",
                  color: token?.icons?.whiteIcon,
                }}
              />
            }
          />
          <Button
            type="link"
            href="https://www.facebook.com/"
            icon={
              <FacebookOutlined
                style={{
                  fontSize: "18px",
                  color: token?.icons?.whiteIcon,
                }}
              />
            }
            target="_blank"
          />
          <Button
            type="link"
            href="https://www.instagram.com/"
            icon={
              <InstagramOutlined
                style={{
                  fontSize: "18px",
                  color: token?.icons?.whiteIcon,
                }}
              />
            }
            target="_blank"
          />
          <Button
            type="link"
            href="https://x.com/"
            icon={
              <XOutlined
                style={{
                  fontSize: "18px",
                  color: token?.icons?.whiteIcon,
                }}
              />
            }
            target="_blank"
          />
          <Button
            type="link"
            href="https://www.youtube.com/"
            icon={
              <YoutubeOutlined
                style={{
                  fontSize: "18px",
                  color: token?.icons?.whiteIcon,
                }}
              />
            }
            target="_blank"
          />
        </div>
        <div className="appFooter-cont__socialMedia__textWrapper">
          <Typography.Text
            className="appFooter-cont__socialMedia__textWrapper__text"
            style={{
              color: token?.Typography?.colorPrimaryText,
            }}>
            LICENSE #:
          </Typography.Text>
          <Typography.Text
            className="appFooter-cont__socialMedia__textWrapper__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            {info?.organization?.license_number
              ? info?.organization?.license_number
              : ""}
          </Typography.Text>
        </div>
        <div className="appFooter-cont__socialMedia__warningWrapper">
          <Typography.Text
            className="appFooter-cont__socialMedia__warningWrapper__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            YOU MUST BE 18 YEARS OF AGE OR OLDER TO VIEW OR SUBMIT INFORMATION
            {shopLoading
              ? ""
              : shop?.name && shop?.is_name_visible_on_shop
              ? ` TO ${shop?.name?.toUpperCase()}`
              : ""}
            .
          </Typography.Text>
        </div>
        <div className="appFooter-cont__socialMedia__warningWrapper">
          <Typography.Text
            className="appFooter-cont__socialMedia__warningWrapper__text"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            <Typography.Text
              className="appFooter-cont__socialMedia__warningWrapper__text"
              style={{
                color: token?.colorError,
                marginRight: "6px",
              }}>
              WARNING:
            </Typography.Text>
            CANNABIS IS A SCHEDULE I CONTROLLED SUBSTANCE. KEEP OUT OF REACH OF
            CHILDREN AND ANIMALS. CANNABIS PRODUCTS MAY ONLY BE POSSESSED OR
            CONSUMED BY PERSONS 21 YEARS OF AGE OR OLDER UNLESS THE PERSON IS A
            QUALIFIED MEDICINAL PATIENT. THE INTOXICATING EFFECTS OF CANNABIS
            PRODUCTS MAY BE DELAYED UP TO TWO HOURS. CANNABIS USE WHILE PREGNANT
            OR BREASTFEEDING MAY BE HARMFUL. CONSUMPTION OF CANNABIS PRODUCTS
            IMPAIRS YOUR ABILITY TO DRIVE AND OPERATE MACHINERY. PLEASE USE
            EXTREME CAUTION.
          </Typography.Text>
        </div>
      </div>
      {/* <div className="appFooter-cont__companyDetails">
        <FooterNavigation
          title="Company"
          items={[
            {
              text: "About Company",
              to: "https://www.youtube.com/",
              target: "_blank",
            },
            {
              text: "Contact Us",
              to: "https://www.youtube.com/",
              target: "",
            },
          ]}
        />
        <FooterNavigation
          title="Discover"
          items={[
            {
              text: "Dispensaries",
              to: "https://www.youtube.com/",
              target: "_blank",
            },
            {
              text: "Dispensaries",
              to: "https://www.youtube.com/",
              target: "",
            },
            {
              text: "Nearby deals",
              to: "https://www.youtube.com/",
              target: "",
            },
            {
              text: "Brands",
              to: "https://www.youtube.com/",
              target: "",
            },
          ]}
        />
        <FooterNavigation
          title="Legal"
          items={[
            {
              text: "Terms of use",
              to: "https://www.youtube.com/",
              target: "_blank",
            },
            {
              text: "Privacy policy",
              to: "https://www.youtube.com/",
              target: "",
            },
            {
              text: "Cookie policy",
              to: "https://www.youtube.com/",
              target: "",
            },
            {
              text: "Your privacy settings",
              to: "https://www.youtube.com/",
              target: "",
            },
          ]}
        />
      </div> */}
    </Footer>
  );
}
