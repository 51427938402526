import React, { useEffect } from "react";
import "./App.scss";
import { ConfigProvider, Layout } from "antd";
import { invertTheme } from "../config/theme";
import { AppFooter, AppHeader, CustomEmpty } from "../components";
import { Routes, Route } from "react-router-dom";
import {
  Checkout,
  Favorites,
  NotFoundPage,
  Products,
  SingleProduct,
} from "../pages";
import { useDispatch, useSelector } from "react-redux";
import { getMe } from "../features/auth/authActions";
import { getShopDetails } from "../features/shopDetails/shopDetailsActions";

export default function App() {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state) => state?.auth?.isAuthenticated);

  useEffect(() => {
    dispatch(getMe());
    dispatch(getShopDetails());
  }, [dispatch, isAuthenticated]);

  return (
    <Layout className="App-cont">
      <ConfigProvider theme={invertTheme} renderEmpty={CustomEmpty}>
        <AppHeader />
        <Routes>
          <Route path="/" element={<Products />} />
          {isAuthenticated ? (
            <Route path="favorites" element={<Favorites />} />
          ) : null}
          <Route path="product/:productId" element={<SingleProduct />} />
          <Route path="checkout" element={<Checkout />} />
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <AppFooter />
      </ConfigProvider>
    </Layout>
  );
}
