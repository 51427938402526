import React from "react";
import "./ProductCard.scss";
import { theme, Card, Image, Button, Rate, Typography } from "antd";
import { ReactComponent as StarIcon } from "../../assets/startIcon.svg";
import { HeartOutlined } from "@ant-design/icons";
import useWindowWidth from "../../hooks/useWindowWidth";
import Utils from "../../utils";
import logo from "../../assets/CanaSaleLogo.svg";

export default function ProductCard({ product, navigate }) {
  const { useToken } = theme;
  const { token } = useToken();
  const { width } = useWindowWidth();
  const parentCategory = Utils?.findParentCategory(product);

  return (
    <Card
      key={`${product?.id}`}
      id={`${product?.id}`}
      className="productCard"
      hoverable
      style={{
        border: "none",
        backgroundColor: token?.Card?.colorBgCard,
        height: "100%",
        maxHeight: width > 2000 ? "373px" : "auto",
      }}
      styles={{
        body: {
          padding: "0px",
          width: "100%",
          height: "100%",
        },
        cover: {
          width: "100%",
          height: "170px",
        },
      }}
      cover={
        <div className="productCard__coverWrapper">
          <Image
            style={{
              width: "100%",
              height: "170px",
            }}
            alt="example"
            src={product?.picture_url ? product?.picture_url : logo}
            preview={false}
          />
          <Button
            className="productCard__coverWrapper__btn"
            type="text"
            size="small"
            icon={
              <HeartOutlined
                style={{
                  color: token?.icons?.colorPrimaryIcon,
                  fontSize: "24px",
                }}
              />
            }
            onClick={(e) => {
              e.stopPropagation();
              console.log("heart clicked");
            }}
          />
        </div>
      }
      onClick={() => {
        if (typeof navigate === "function") {
          navigate();
        }
      }}>
      <div className="productCard__body">
        <Typography.Text className="productCard__body__text">
          Category: {parentCategory ? parentCategory?.name : ""}
        </Typography.Text>
        <Typography.Text
          className="productCard__body__bigText"
          style={{
            color: token?.Typography?.mainColorText,
          }}>
          {product?.name ? product?.name : ""}
        </Typography.Text>
        <div className="productCard__body__rateWrapper">
          <div
            onClick={(e) => {
              e.stopPropagation();
            }}>
            <Rate
              allowHalf
              value={product?.rate ? product?.rate : 0}
              character={
                <StarIcon
                  style={{
                    fontSize: "12px",
                  }}
                />
              }
              count={5}
              style={{
                fontSize: "12px",
              }}
              onChange={(rate) => {
                console.log("Rate", rate);
              }}
            />
          </div>

          <Typography.Text className="productCard__body__text">
            {product?.rate ? product?.rate : 0}
          </Typography.Text>
        </div>
        <Typography.Text className="productCard__body__text">
          {`Thc : ${
            product?.cannabinoids?.thc ? `${product?.cannabinoids?.thc}%` : ""
          }`}
        </Typography.Text>
        <div className="productCard__body__variantWrapper">
          {Utils?.getMinandMaxVariants(
            product?.variants?.length ? product?.variants : []
          )?.map((variant) => {
            const currentTime = Date.now(); // Get current time in milliseconds
            const randomNumber = Math.floor(Math.random() * 1000) + 1; // Generate random number between 1 and 1000
            const uniqueNumber = currentTime + randomNumber;
            return product?.sale ? (
              <Typography.Text
                className="productCard__body__text"
                key={variant?.id}>
                <Typography.Text
                  className="productCard__body__text"
                  key={product?.sale?.id}
                  style={{
                    color: token?.Typography?.colorPrimaryText,
                  }}
                  delete>
                  {variant?.price
                    ? `${Utils?.roundNumber(variant?.price)}`
                    : "$"}
                </Typography.Text>
                {variant?.price
                  ? ` ${
                      variant?.name && variant?.unit
                        ? `${Utils?.showProductDetail(variant)} - `
                        : ""
                    } ${
                      variant?.price && product?.sale?.amount
                        ? Utils?.roundNumber(
                            Number(variant?.price) -
                              Number(
                                (variant?.price * product?.sale?.amount) / 100
                              )
                          )
                        : "$"
                    }`
                  : "$"}
              </Typography.Text>
            ) : (
              <Typography.Text
                className="productCard__body__text"
                key={variant?.id ? variant?.id : uniqueNumber}>
                {variant?.price
                  ? `${
                      variant?.name && variant?.unit
                        ? `${Utils?.showProductDetail(variant)} - `
                        : ""
                    } ${Utils?.roundNumber(variant?.price)}`
                  : "$"}
              </Typography.Text>
            );
          })}
        </div>
        <Button
          block
          style={{
            marginTop: "auto",
          }}
          onClick={(e) => {
            e.stopPropagation();
            if (typeof navigate === "function") {
              navigate();
            }
          }}>
          Add to Cart
        </Button>
      </div>
    </Card>
  );
}
