import { createSlice } from "@reduxjs/toolkit";
import { getMenuItems, getMenuItem } from "./menuItemsAction";

const initialState = {
  loading: false,
  products: {},
  success: false,
  error: null,
  singleProduct: {},
};
const menuItemsSlice = createSlice({
  name: "menuItems",
  initialState,
  reducers: {
    resetMenuItemsError: (state, action) => {
      if (action?.payload) {
        delete state.error[action?.payload];
        if (Object.keys(state.error)?.length === 0) {
          state.error = null;
        }
      } else {
        state.error = null;
      }
      state.loading = false;
      state.success = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMenuItems.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMenuItems.fulfilled, (state, action) => {
        state.products = action.payload;
        state.loading = false;
      })
      .addCase(getMenuItems.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(getMenuItem.pending, (state) => {
        state.loading = true;
      })
      .addCase(getMenuItem.fulfilled, (state, action) => {
        state.singleProduct = action.payload;
        state.loading = false;
      })
      .addCase(getMenuItem.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export const { resetMenuItemsError } = menuItemsSlice.actions;
export default menuItemsSlice.reducer;
