import React, { useEffect, useState, useCallback, useRef } from "react";
import "./Favorites.scss";
import { theme, Layout, Pagination, Spin, notification } from "antd";
import useWindowWidth from "../../hooks/useWindowWidth";

import { useLocation, useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { getMenuItems } from "../../features/menuItems/menuItemsAction";
import { resetMenuItemsError } from "../../features/menuItems/menuItemsSlice";
import { CustomEmpty, ProductCard } from "../../components";
import classNames from "classnames";

const { Content } = Layout;

export default function Favorites() {
  const { useToken } = theme;
  const { token } = useToken();
  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = useCallback(
    (type, title, text) => {
      api[type]({
        message: title,
        description: text,
        placement: "top",
      });
    },
    [api]
  );
  const { width, isMobile } = useWindowWidth();
  const location = useLocation();
  const navigate = useNavigate();
  const { isAuthenticated } = useSelector((state) => state?.auth);
  const {
    loading,
    products: { meta, results: products },
    error,
  } = useSelector((state) => state?.menuItems);
  const dispatch = useDispatch();
  const productContRef = useRef(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);

  const getProductsByFilter = (params) => {
    dispatch(
      getMenuItems({
        page: page ? page : undefined,
        per_page: perPage ? perPage : undefined,
        favorite: true,
        count__gt: 0,
        ...params,
      })
    );
  };

  useEffect(() => {
    dispatch(
      getMenuItems({ page: 1, per_page: 10, favorite: true, count__gt: 0 })
    );
  }, [dispatch, isAuthenticated]);

  const handlePagination = (e, a) => {
    setPage(e);
    setPerPage(a);
    getProductsByFilter({ page: e, per_page: a });
  };

  useEffect(() => {
    if (location?.pathname === "/favorites") {
      if (error && !loading) {
        for (let key in error) {
          openNotificationWithIcon("error", key, error[key]);
        }
        dispatch(resetMenuItemsError());
      }
    }
  }, [error, loading, location?.pathname, openNotificationWithIcon, dispatch]);

  return (
    <>
      {contextHolder}
      <Content
        className="favorites-cont"
        style={{
          backgroundColor: token?.Layout?.contentBg,
        }}>
        <div
          ref={productContRef}
          style={{
            gridTemplateColumns:
              !loading && products?.length
                ? `repeat(auto-fit, minmax(${isMobile ? "168px" : "177.8px"}, ${
                    isMobile ? 350 : 250 / productContRef?.current?.offsetWidth
                  }fr))`
                : `repeat(auto-fit, minmax(${
                    isMobile ? "168px" : "177.8px"
                  },1fr))`,
          }}
          className={classNames("favorites-cont__products", {
            "favorites-cont__products__spinWrapper":
              loading || !products?.length,
          })}>
          {!products?.length && !loading ? (
            <CustomEmpty
              circleColor={token?.colorText ? token?.colorText : ""}
              boxBorderColor={token?.colorText ? token?.colorText : ""}
            />
          ) : !loading ? (
            products?.map((product) => (
              <ProductCard
                key={product?.id}
                product={product}
                navigate={() => {
                  navigate(`/product/${product?.id}`, { state: product });
                }}
              />
            ))
          ) : (
            <Spin size="large" />
          )}
        </div>

        <Pagination
          className="favorites-cont__pagination"
          disabled={loading}
          pageSize={perPage}
          current={page}
          defaultCurrent={1}
          total={meta?.count ? meta?.count : ""}
          responsive={true}
          onChange={handlePagination}
          showQuickJumper={width < 584 && width >= 577 ? false : true}
        />
      </Content>
    </>
  );
}
