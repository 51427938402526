import React, { useEffect, useState } from "react";
import "./ProductSIze.scss";
import { Button, Typography, Spin, message } from "antd";
import { Counter } from "../../../../components";
import Utils from "../../../../utils";
import useCard from "../../../../hooks/useCard";

export default function ProductSIze({ token, product, loading }) {
  const [messageApi, contextHolder] = message.useMessage();
  const openSuccess = () => {
    messageApi.open({
      type: "success",
      content: "Product added to cart",
    });
  };
  const { addToCard } = useCard();
  const [count, setCount] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState(null);

  useEffect(() => {
    if (product?.variants?.length) {
      setSelectedVariant(product?.variants[0]);
    } else {
      setSelectedVariant(null);
    }
  }, [product]);

  return (
    <>
      {contextHolder}
      <div className="productSIze-cont">
        {loading ? (
          <Spin
            size="large"
            style={{
              marginTop: "auto",
              marginBottom: "auto",
            }}
          />
        ) : (
          <>
            <div className="productSIze-cont__titleCont">
              {product?.sale && selectedVariant ? (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    gap: "8px",
                    alignItems: "center",
                  }}>
                  <Typography.Text
                    key={selectedVariant?.id ? selectedVariant?.id : ""}
                    className="productSIze-cont__titleCont__bigTitle"
                    style={{
                      color: token?.Typography?.colorPrimaryText,
                    }}
                    delete>
                    {selectedVariant?.price
                      ? Utils?.roundNumber(
                          count
                            ? count * selectedVariant?.price
                            : selectedVariant?.price
                        )
                      : ""}
                  </Typography.Text>
                  <Typography.Text
                    className="productSIze-cont__titleCont__bigTitle"
                    style={{
                      color: token?.Typography?.mainColorText,
                    }}
                    key={product?.sale?.id}>
                    {selectedVariant?.price && product?.sale?.amount
                      ? Utils?.roundNumber(
                          count
                            ? count *
                                (Number(selectedVariant?.price) -
                                  Number(
                                    (selectedVariant?.price *
                                      product?.sale?.amount) /
                                      100
                                  ))
                            : Number(selectedVariant?.price) -
                                Number(
                                  (selectedVariant?.price *
                                    product?.sale?.amount) /
                                    100
                                )
                        )
                      : ""}
                  </Typography.Text>
                </div>
              ) : (
                <Typography.Text
                  className="productSIze-cont__titleCont__bigTitle"
                  style={{
                    color: token?.Typography?.mainColorText,
                  }}>
                  {selectedVariant?.price
                    ? Utils?.roundNumber(
                        count
                          ? selectedVariant?.price * count
                          : selectedVariant?.price
                      )
                    : ""}
                </Typography.Text>
              )}
            </div>
            {/* <div className="productSIze-cont__slidersCont">
        <div className="productSIze-cont__slidersCont__slidercont">
          <Typography.Text
            className="productSIze-cont__secondaryTitle"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Relaxed
          </Typography.Text>
          <div
            className="productSIze-cont__slidersCont__slidercont__slide"
            style={{
              backgroundColor: token?.Card?.colorBgCard,
            }}>
            <div
              className="productSIze-cont__slidersCont__slidercont__slide__active"
              style={{
                backgroundColor: token?.colorPrimary,
                width: "50%",
              }}></div>
          </div>
        </div>
        <div className="productSIze-cont__slidersCont__slidercont">
          <Typography.Text
            className="productSIze-cont__secondaryTitle"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Boner
          </Typography.Text>
          <div
            className="productSIze-cont__slidersCont__slidercont__slide"
            style={{
              backgroundColor: token?.Card?.colorBgCard,
            }}>
            <div
              className="productSIze-cont__slidersCont__slidercont__slide__active"
              style={{
                backgroundColor: token?.colorPrimary,
                width: "90%",
              }}></div>
          </div>
        </div>
        <div className="productSIze-cont__slidersCont__slidercont">
          <Typography.Text
            className="productSIze-cont__secondaryTitle"
            style={{
              color: token?.Typography?.mainColorText,
            }}>
            Happy
          </Typography.Text>
          <div
            className="productSIze-cont__slidersCont__slidercont__slide"
            style={{
              backgroundColor: token?.Card?.colorBgCard,
            }}>
            <div
              className="productSIze-cont__slidersCont__slidercont__slide__active"
              style={{
                backgroundColor: token?.colorPrimary,
                width: "70%",
              }}></div>
          </div>
        </div>
      </div> */}
            <div className="productSIze-cont__weightCont">
              <Typography.Text
                className="productSIze-cont__secondaryTitle"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                Select Weight
              </Typography.Text>
              <div className="productSIze-cont__weightCont__btnWrapper">
                {product?.variants?.map((variant) => (
                  <Button
                    className="productSIze-cont__weightCont__btnWrapper__weight"
                    style={{
                      borderColor:
                        variant?.id?.toString() ===
                        selectedVariant?.id?.toString()
                          ? token?.colorPrimary
                          : "",
                    }}
                    key={variant?.id}
                    onClick={() => {
                      setSelectedVariant(variant);
                    }}>
                    <Typography.Text
                      className="productSIze-cont__secondaryTitle"
                      style={{
                        color:
                          variant?.id?.toString() ===
                          selectedVariant?.id?.toString()
                            ? token?.colorPrimary
                            : "",
                      }}>
                      {variant?.name && variant?.unit
                        ? Utils?.showProductDetail(variant)
                        : ""}
                    </Typography.Text>
                  </Button>
                ))}
              </div>
            </div>
            <div className="productSIze-cont__countCont">
              <Typography.Text
                className="productSIze-cont__secondaryTitle"
                style={{
                  color: token?.Typography?.mainColorText,
                }}>
                Quantity
              </Typography.Text>
              <Counter
                count={count}
                onCauntChnage={(val) => {
                  setCount(val);
                }}
              />
            </div>
            <Button
              disabled={loading || !selectedVariant}
              onClick={() => {
                addToCard(selectedVariant, count, product);
                openSuccess();
              }}
              style={{ width: "100%", maxWidth: "147px" }}>
              Add to Cart
            </Button>
          </>
        )}
      </div>
    </>
  );
}
